import Welcome from '../components/welcome.js';

function Home() {
    return  <>

  <Welcome />

</>;
  
};

export default Home;