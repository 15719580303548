
function Direct() {
    return  <>
<h2><i class="fa-solid fa-comment"></i> Direct Messages</h2>
<p>Closer than ever...</p>

  </>;
  };

  export default Direct;
