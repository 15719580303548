
function Contributors() {
    return  <>
<h2><i class="fa-solid fa-users"></i> Contributors</h2>
<h2>M. Altuğ Jakal</h2>
<p>Head Developer - Founder</p>

  </>;
  };

  export default Contributors;