
function Welcome() {
    return  <>

  <div className="welcome">
    <h1>
      <strong>Crumbs</strong>
    </h1>
    <h2>
      Join the crowd
    </h2>
  </div>



</>;
  
};

export default Welcome;